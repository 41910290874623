/* eslint-disable pincites/disallow-using-fluent-ui-icons-directly */
import React from "react";
import {
  Add16Filled,
  Add16Regular,
  ArrowDownloadFilled,
  ArrowDownloadRegular,
  ArrowLeft24Regular,
  ArrowNextFilled,
  ArrowNextRegular,
  ArrowPreviousFilled,
  ArrowPreviousRegular,
  ArrowRepeatAllFilled,
  ArrowRepeatAllRegular,
  ArrowRight12Regular,
  ArrowSyncCheckmark24Regular,
  ArrowUndoFilled,
  ArrowUndoRegular,
  ArrowUploadRegular,
  BookFilled,
  BookRegular,
  bundleIcon,
  Checkmark20Regular,
  CheckmarkSquare20Regular,
  ChevronDown12Filled,
  ChevronRight12Filled,
  CircleLineFilled,
  CircleLineRegular,
  CopySelectFilled,
  CopySelectRegular,
  DeleteFilled,
  DeleteRegular,
  Dismiss24Regular,
  DocumentArrowUpRegular,
  DocumentTextExtract24Regular,
  EditFilled,
  EditRegular,
  ErrorCircle20Regular,
  FilterFilled,
  FilterRegular,
  HistoryFilled,
  HistoryRegular,
  InfoFilled,
  InfoRegular,
  LightbulbFilled,
  LightbulbRegular,
  MoreHorizontal20Regular,
  MoreHorizontalFilled,
  MoreHorizontalRegular,
  OpenRegular,
  PeopleTeamFilled,
  PeopleTeamRegular,
  PersonFeedback20Filled,
  PersonFeedback20Regular,
  PersonFilled,
  PersonRegular,
  PlayCircleFilled,
  PlayCircleRegular,
  RocketFilled,
  RocketRegular,
  Save20Regular,
  SaveCopyFilled,
  SaveCopyRegular,
  SignOutFilled,
  SignOutRegular,
  SparkleFilled,
  CheckboxArrowRightRegular,
  ErrorCircleRegular,
  CheckmarkCircleRegular,
  QuestionCircleRegular,
  DismissCircleRegular,
  SubtractCircleRegular,
  SparkleRegular,
  SearchRegular,
  SkipForwardTabRegular,
  EyeFilled,
  EyeRegular,
  LibraryFilled,
  LibraryRegular,
} from "@fluentui/react-icons";
import { PincitesButton } from "./PincitesButton";
import { PincitesButtonType } from "../../utils/buttonUtils";
import { Tooltip } from "@fluentui/react-components";

export const AddIcon = bundleIcon(Add16Filled, Add16Regular);
export const ArrowLeftIcon = ArrowLeft24Regular; // Used for back button in navigation
export const ArrowRightIcon = ArrowRight12Regular;
export const ArrowSyncCheckmarkIcon = ArrowSyncCheckmark24Regular;
export const ArrowUploadIcon = ArrowUploadRegular;
export const CheckmarkCircleIcon = CheckmarkCircleRegular;
export const CheckmarkIcon = Checkmark20Regular; // Only used on the banner
export const CheckmarkSquareIcon = CheckmarkSquare20Regular;
export const ChevronDownIcon = ChevronDown12Filled;
export const ChevronRightIcon = ChevronRight12Filled;
export const CircleLineIcon = bundleIcon(CircleLineFilled, CircleLineRegular);
export const ClauseIcon = DocumentTextExtract24Regular;
export const CopySelectIcon = bundleIcon(CopySelectFilled, CopySelectRegular);
export const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
export const DismissCircleIcon = DismissCircleRegular;
export const DismissIcon = Dismiss24Regular;
export const DocumentDownloadIcon = ArrowDownloadRegular;
export const DocumentUploadIcon = DocumentArrowUpRegular;
export const DownloadIcon = bundleIcon(ArrowDownloadFilled, ArrowDownloadRegular);
export const EditIcon = bundleIcon(EditFilled, EditRegular);
export const ErrorCircleIcon = ErrorCircleRegular;
export const ErrorIcon = ErrorCircle20Regular;
export const EyeIcon = bundleIcon(EyeFilled, EyeRegular);
export const FeedbackIcon = bundleIcon(PersonFeedback20Filled, PersonFeedback20Regular);
export const FilterIcon = bundleIcon(FilterFilled, FilterRegular);
export const HistoryIcon = bundleIcon(HistoryFilled, HistoryRegular);
export const InfoIcon = bundleIcon(InfoFilled, InfoRegular);
export const LightbulbIcon = bundleIcon(LightbulbFilled, LightbulbRegular);
export const MoreHorizontalIcon = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);
export const NextIcon = bundleIcon(ArrowNextFilled, ArrowNextRegular);
export const OpenIcon = OpenRegular;
export const PersonIcon = bundleIcon(PersonFilled, PersonRegular);
export const PlayCircleIcon = bundleIcon(PlayCircleFilled, PlayCircleRegular);
export const PlaybookIcon = bundleIcon(BookFilled, BookRegular);
export const CheckLibraryIcon = bundleIcon(LibraryFilled, LibraryRegular);
export const UsersIcon = bundleIcon(PeopleTeamFilled, PeopleTeamRegular);
export const PreviousIcon = bundleIcon(ArrowPreviousFilled, ArrowPreviousRegular);
export const PublishIcon = bundleIcon(RocketFilled, RocketRegular);
export const QuestionCircleIcon = QuestionCircleRegular;
export const RescanIcon = bundleIcon(ArrowRepeatAllFilled, ArrowRepeatAllRegular);
export const SaveIcon = Save20Regular;
export const SaveCopyIcon = bundleIcon(SaveCopyFilled, SaveCopyRegular);
export const SearchIcon = SearchRegular;
export const SignOutIcon = bundleIcon(SignOutFilled, SignOutRegular);
export const SkipIcon = SkipForwardTabRegular;
export const SparkleIcon = bundleIcon(SparkleFilled, SparkleRegular);
export const SubtractCircleIcon = SubtractCircleRegular;
export const TestIcon = CheckboxArrowRightRegular;
export const UnpublishIcon = bundleIcon(ArrowUndoFilled, ArrowUndoRegular);

export function EditIconButton({
  onClick,
  tooltipText,
  ariaLabel,
  isDisabled,
}: {
  onClick?: () => void;
  tooltipText?: string;
  ariaLabel?: string;
  isDisabled?: boolean;
}): React.JSX.Element {
  return (
    <PincitesButton
      buttonType={PincitesButtonType.SUBTLE}
      icon={<EditIcon />}
      ariaLabel={ariaLabel}
      onClick={onClick}
      tooltipContent={tooltipText}
      isDisabled={isDisabled}
    />
  );
}

export function DeleteIconButton({
  onClick,
  tooltipText,
  ariaLabel,
  isDisabled,
}: {
  onClick?: () => void;
  tooltipText?: string;
  ariaLabel?: string;
  isDisabled?: boolean;
}): React.JSX.Element {
  return (
    <PincitesButton
      buttonType={PincitesButtonType.SUBTLE}
      icon={<DeleteIcon />}
      ariaLabel={ariaLabel ?? "Delete"}
      onClick={onClick}
      tooltipContent={tooltipText}
      isDisabled={isDisabled}
    />
  );
}

export function MoreHorizontalIconButton({
  onClick,
  index,
}: {
  onClick: () => void;
  index?: number;
}): React.JSX.Element {
  const uniqueId = index ? `${name}-${index}` : `${name}`; // create unique id

  return (
    <Tooltip content="More details" positioning="above-start" withArrow relationship="label">
      <MoreHorizontal20Regular tabIndex={0} onClick={onClick} key={uniqueId} />
    </Tooltip>
  );
}
